export const start_register = () =>{
    return {
        type:'start_register'
    }
}
export const start_verefy_email = () =>{
    return {
        type:'start_verefy_email'
    }
}
export const start_resend_verify_mail = () =>{
    return {
        type:'start_resend_verify_mail'
    }
}
export const start_login = () =>{
    return {
        type:'start_login'
    }
}
export const start_get_user = () =>{
    return {
        type:'start_get_user'
    }
}
export const start_logout = () =>{
    return {
        type:'start_logout'
    }
}
export const start_forgot_password =() =>{
    return {
        type:'start_forgot_password'
    }
}
export const start_forgot_password_code = () =>{
    return {
        type:'start_forgot_password_code'
    }
}

export const start_create_new_password = () =>{
    return {
        type:'start_create_new_password'
    }
}
export const start_change_username_and_name = () =>{
    return{
        type:'start_change_username_and_name'
    }
} 
export const start_update_password = () =>{
    return {
        type:'start_update_password'
    }
}
export const start_change_email = () =>{
    return {
        type:'start_change_email'
    }
}
export  const start_check_email_code = () =>{
    return {
        type:'start_check_email_code'
    }
}
export const start_create_folder =() =>{
    return {
        type:'start_create_folder'
    }
}
export const start_get_all_folder = () =>{
    return {
        type:'start_get_all_folder'
    }
}
export const start_get_folfer_by_slug = () =>{
    return {
        type:'start_get_folfer_by_slug'
    }
}
export const start_add_photo = () =>{
    return {
        type:'start_add_photo'
    }
}
export const start_get_photo = () =>{
    return {
        type:'start_get_photo'
    }
}
export const start_delate_photo = () =>{
    return {
        type:'start_delate_photo'
    }
}
export const start_get_slider_data = () =>{
    return {
        type:'start_get_slider_data'
    }
}